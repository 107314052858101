.container {
  margin-left: 20px; 
  padding-right: 10px;
}
.workbench {
    font-size: 12px;  
    color: #101828;          
    padding: 10px;       
    margin: 10px;
    text-align: left;   
    width: 180px;         
}
