.popupModal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.00005);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popupContent {
    background: white;
    padding: 20px; /* Increased padding for larger content area */
    border-radius: 20px;
    position: relative;
    width: 350px;
    height: auto;
    box-shadow: 0 4px 8px rgba(0,0,0,0.3);
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Aligns children to the start (left side) */
}

.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    font-size: 1.5em;
    cursor: pointer;
    stroke: var(--Gray-400, #98A2B3);
}

.titleBox {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Ensures title and subtitle align to the left */
}

.title {
    color: var(--Gray-900, #101828);
    font-family: Inter;
    font-size: 24px;
    font-weight: bold;
    line-height: 24px;
    margin-bottom: 10px;
}

.subtitle {
    color: var(--Gray-600, #475467);
    font-family: Inter;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    margin-top: 5px;
}

