
.container {
    display: flex;
    height: 100vh; 
    overflow-y: auto;
}

.button{
    display: flex;
    padding: 6.4px 15px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    border-radius: 2px;
    border: 1px #283362;

    background: #283362;

    /* drop-shadow/button-primary */
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
    margin-top: 20px;
}

.customInputNumber {
    border: none !important; /* 移除边框 */
}

/* 覆盖禁用状态下的文字颜色 */
.customInputNumber input[disabled] {
    color: #475467 !important; /* 黑色文字 */
    background-color: #F9FAFB !important;
    cursor: default !important;
}

/* 如果你需要覆盖 $ 前缀的样式 */
.customInputNumber .ant-input-number-prefix {
    color: #475467 !important; /* 确保 $ 符号的颜色为黑色 */
}

.customDisabledInput {
    border: none
}

/* 覆盖禁用状态下的 $ 前缀样式 */
.customDisabledInput .ant-input-prefix {
    color: #475467 !important; /* 确保 $ 符号的颜色 */
}

/* 确保禁用状态下的文字颜色 */
.customDisabledInput input[disabled] {
    color: #475467 !important; /* 黑色文字 */
    background-color: #F9FAFB !important;
    cursor: default !important;
}