html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%; /* 确保宽度填满整个视窗 */
  overflow: hidden; /* 防止滚动条影响布局 */
  box-sizing: border-box;
}

.main-container {
  display: flex;
  width: 100vw; /* 容器宽度为视窗宽度 */
  height: 100vh; /* 容器高度为视窗高度 */
}

.left-container {
  background-image: url('../../../public/log-bg.png'); /* 背景图片 */
  background-size: cover;
  background-position: center;
  flex: 1; /* 占据一半的宽度 */
  width: 50%; /* 明确宽度为50% */
  height: 100vh; /* 高度填满整个屏幕 */
  height: 100%; /* 同样高度填满整个屏幕 */
  flex: 1; /* 使用flex填充剩余空间 */
  margin: 0; /* 消除默认边距 */
  padding: 0; /* 消除内边距 */

  display: flex; /* 设置为 flex 容器 */
  justify-content: center; /* 水平居中 */
  flex-direction: column;
  align-items: center; /* 垂直居中 */
  width: 50%; /* 根据需求调整宽度 */
  height: 100vh; /* 高度充满视窗的高度 */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.centered-img {
  max-width: 100%; /* 确保图片宽度不超过容器宽度 */
  max-height: 100%; /* 确保图片高度不超过容器高度 */
  width: 270px; /* 或任何你想要的宽度 */
  height: auto;
  margin-bottom: 5px;
}

.text_crm {
  font-size: 24px; /* 根据需求调整字体大小 */
  color: #fff; /* 字体颜色，根据背景调整以确保可见性 */
  text-align: center; /* 文本居中对齐 */
}

.right-container {
  flex: 1; /* 占据一半的宽度 */
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  padding: 20px;
  box-sizing: border-box;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; /* 可以根据需要设置宽度 */
  max-width: 360px; /* 控制登录表单的最大宽度 */
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  width: 100%;
}

.text-supporting {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
}

.text {
  width: 100%;
  height: 38px;
  color: #0f1728;
  font-family: Inter, var(--default-font-family);
  font-size: 30px;
  font-weight: 600;
  line-height: 38px;
  text-align: left;
}

.supporting-text {
  width: 100%;
  height: 24px;
  color: #475466;
  font-family: Inter, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.content-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
  border-radius: 12px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
}

.input-field, .input-field-5 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  width: 100%;
}

.input-with-label, .input-with-label-6 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  width: 100%;
}

.label-wrapper, .label-wrapper-7 {
  display: flex;
  align-items: flex-start;
  gap: 2px;
  width: 100%;
}

.label, .label-8 {
  height: 20px;
  color: #344053;
  font-family: Inter, var(--default-font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  white-space: nowrap;
}

.input, .input-9 {
  display: flex;
  align-items: center;
  padding: 10px 14px;
  background: #ffffff;
  border: 1px solid #cfd4dc;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  width: 100%;
}

.input-2, .input-a {
  width: 100%;
  height: 24px;
  color: #667084;
  font-family: Inter, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: none;
  outline: none;
}

.row {
  display: flex;
  align-items: center;
  width: 100%;
}

.checkbox {
  display: flex;
  align-items: center;
  gap: 8px;
}

.checkbox-base {
  width: 16px;
  height: 16px;
  border: 1px solid #cfd4dc;
  border-radius: 4px;
}

.text-supporting-e {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.text-f {
  height: 20px;
  color: #344053;
  font-family: Inter, var(--default-font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}

.buttons-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 114px;
}

.text-10 {
  height: 20px;
  color: #3c3f96;
  font-family: Inter, var(--default-font-family);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
  background: transparent;
  border: none;
  cursor: pointer;
}

.buttons-button-11 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 22px;
  background: #3c3f96;
  border-radius: 8px;
  width: 100%;
  margin-left: 33px;
}

.text-12 {
  height: 24px;
  color: #ffffff;
  font-family: Inter, var(--default-font-family);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}

.label-error {
  height: 20px;
  color: #ef2b03;
  font-family: Inter, var(--default-font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}
.signup-container {
  text-align: center;
  margin-top: 10px;
}
.forgotpassword-container {
  display: flex;
  text-align: center;
  margin-top: -20px;
}
.signup-text {
  color: gray;
}

.signup-link {
  color: blue;
  margin-left: 5px;
  cursor: pointer;
  text-decoration: none;
}

.signup-link:hover {
  text-decoration: underline;
}