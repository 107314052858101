.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modalContent {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
}

.closeButton {
    background: none;
    border: none;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.titleBox {
    margin-bottom: 20px;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.title {
    color: var(--Gray-900, #101828);
    font-family: Inter;
    font-size: 25px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 5px;
}

.subtitle {
    color: var(--Gray-600, #475467);
    font-family: Inter;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    margin-top: 5px;
}

.inputField {
    margin-bottom: 15px;
}

.label {
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
    color: #333;
}

.input {
    width: 95%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
}

.submitButton {
    width: 100%;
    padding: 10px;
    background-color: #4A90E2;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
}

.submitButton:hover {
    background-color: #357ABD;
}

.error {
    color: red; /* Set the text color to red */
    font-size: 14px; /* Optional: Adjust font size as needed */
    margin-top: 8px; /* Optional: Adjust spacing as needed */
}

.statusContainer {
    display: flex;
    align-items: center;
    margin-left: 20px;
  }

  .viewAllLink {
    font-size: 16px;
    font-weight: 500;
    color: #344054;
    margin: 0.5rem 0 0 0;
  }

  .projectContainer {
    display: flex;
    padding: 10px 14px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid var(--Gray-300, #D0D5DD);
    background: var(--Base-White, #FFF);

    /* Shadows/shadow-xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  .buttonContainer {
    display: flex;
    padding: 32px 24px 14px 14px;
    justify-content: center;
    align-items: flex-start;
    gap: 80px;
    align-self: stretch;
  }
  
  .cancelButton, .confirmButton {
    padding: 10px 20px;
    border-radius: 4px;
    border: none;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .cancelButton {
    background-color: #ffffff;
    color: #000000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid var(--Gray-300, #D0D5DD);
  }
  
  .confirmButton {
    background-color: #4285f4;
    color: #ffffff;
  }
  
  .cancelButton:hover {
    background-color: #f8f8f8;
  }
  
  .confirmButton:hover {
    background-color: #3367d6;
  }