.projectListContainer {
    width: 100%;
    padding: 1rem 2rem;
    padding-top: 15px;
    box-sizing: border-box;
  }
  
  .projectListHeader {
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
  }
  
  .projectListTitle {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    margin: 0;
    white-space: nowrap; /* 防止换行 */
  }
  
  .viewAllLink {
    font-size: 14px;
    font-weight: 500;
    color: #344054;
    margin: 0.5rem 0 0 0;
    white-space: nowrap; /* 防止换行 */
  }
  
  .projectListFilters {
    display: flex; /* 改为flex确保整个容器宽度撑满，而不是inline-flex */
    align-items: center;
    justify-content: space-between; /* 添加这个属性 */
    width: 100%; /* 确保容器宽度撑满父元素 */
    margin-top: 10px;
}
  .searchInput {
    width: 100%;
    padding: 8px 12px;
    font-size: 16px;
    border-radius: 6px;
    border: 1px solid #dcdcdc;
  }
  
  .statusContainer {
    display: flex;
    align-items: center;
    margin-left: 20px;
  }
  
  .statusIndicator {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #34a853;
    margin-right: 8px;
  }
  
  .statusSelect {
    padding: 8px 12px;
    font-size: 16px;
    border-radius: 6px;
    border: 1px solid #dcdcdc;
    cursor: pointer;
  }


  .projectManagement {
    position: relative;
    top: 0;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.createButton {
  width: 177px; /* 设置固定宽度 */
  height: 44px; 
  background-color: #F5853F;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  flex-shrink: 0;
  margin-right: -0.8rem;
  margin-top: -16px;
}

.searchContaine{
  margin-left: auto;
  margin-right: -1.5%;
}