.cardContainer {
    display: flex;
    max-width: 1280px;
    padding: 0px 32px;
    align-items: flex-start;
    align-content: flex-start;
    gap: 24px;
    align-self: stretch;
    flex-wrap: wrap;
    margin-top: 20px;
}

.pointsCard {
    display: flex;
    width: 500px; /* Increased width */
    padding: 24px 48px; /* Adjusted padding for more elongation */
    align-items: flex-end;
    gap: 24px;
    border-radius: 12px;
    border: 1px solid var(--Gray-200, #E4E7EC);
    background: #283362;
}


.pointsContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    flex: 1 0 0;
}

.pointsHead{
    align-self: stretch;
    color: #FFF;

    /* Text md/Semibold */
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 150% */
}

.HeadingAndNumber{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    margin-top: -16px;
}

.pointsHeading{
    align-self: stretch;
    color: #E6E6E6;
    /* Text sm/Medium */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 142.857%;
    margin-top: -8px;
}
.pointsNumberContainer{
    display: flex;
    align-items: center;
    gap: 16px;
    margin-top: -24px;
    align-self: stretch;
}
.iconCoins{
    width: 30px;
    height: 30px;
}

.pointsNumber{
    flex: 1 0 0;
    color: #FFF;
    /* Display sm/Semibold */
    font-family: Inter;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 126.667%;
}

.pointsButtonContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
}

.pointsButton{
    display: flex;
    padding: 16px 12px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    border: 2px solid var(--Gradient-skeuemorphic-gradient-border, rgba(255, 255, 255, 0.12));
    background: #DC8F4C;
}

.buttontextContainer{
    display: flex;
    padding: 0px 2px;
    justify-content: center;
    align-items: center;
}

.buttonTxt{
    color: var(--Base-White, #FFF);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 142.857%;
    padding: 8px 8px; /* 增加了垂直方向的 padding */
}

.pointsButton:hover .buttonTxt {
    color: #DC8F4C; /* 设置 hover 状态时的文字颜色为黑色 */
}

.informationContainer {
    width: 500px; /* Increased width */
    height: 162px; /* Made height auto to adapt to content */
}

.informationContent {
    display: flex;
    width: 100%; /* Set width to 100% of the container */
    padding: 52px;
    flex-direction: column; /* Ensures elements are aligned in a column */
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 16px;
    background: var(--Gray-50, #F9FAFB);
}

.groupSeven{
    height: 118px;
    flex-shrink: 0;
    align-self: stretch;
}

.FAQitem{
display: flex;
width: 398px;
height: 112px;
min-width: 320px;
flex-direction: column;
justify-content: center;
align-items: center;
flex-shrink: 0;
}

.FAQContent{
    display: flex;
    height: 112px;
    align-items: flex-start;
    gap: 24px;
    flex-shrink: 0;
    align-self: stretch;
}

.IconWrap{
    display: flex;
    padding-top: 2px;
    flex-direction: column;
    align-items: flex-start;
}

.supportingText{
    display: flex;
    height: 132px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
}

.supportingTextTitle{
    align-self: stretch;
    color: var(--Gray-900, #101828);
    /* Text lg/Medium */
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 155.556% */
}

.supportingTextPargraph{
    height: 66px;
    flex-shrink: 0;
    align-self: stretch;
    color: var(--Gray-600, #6e7c91);

    /* Text md/Regular */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 150% */
}

.discount{
    color: #DC8F4C;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.group23{
    width: 38px;
    height: 24px;
    flex-shrink: 0;
    margin-left:330px;
    margin-top: -15px;
}
.viewTxt{
    color: #DC8F4C;

/* Text md/Medium */
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 150% */
}

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.closeButton {
    background: none;
    border: none;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.titleBox {
    margin-bottom: 20px;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.title {
    color: #FFF;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 155.556%;
}

.subtitle {
    color: #FFF;

    /* Text sm/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}

.modalContent {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
}


.pointsContainer {
    background-image: url(../../../public/paymentBg_copy.png);
    /* background-color: #283362; */
    padding: 20px; /* 保持内边距 */
    border-radius: 8px 8px 0 0;
    color: #ffffff; /* 文本颜色 */
    width: calc(100% +5px); /* 扩大宽度以抵消 modalContent 的内边距 */
    margin: -20px -20px 0 -20px; /* 使用负边距抵消 modalContent 的内边距，并向上覆盖 */
    margin-bottom: 25px;
    background-size: cover; /* 确保图片覆盖整个容器 */
    background-position: center; /* 图片居中显示 */

}

.pointsInfo{
    color: #FFF;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; 
}