.overall {
    height: 100vh;  /* 视窗的100%高度 */
    overflow-y: none;  /* 内容超出时显示滚动条 */
    overflow-x: auto;
}
.container {
    display: flex;  
    height: 100vh;
}

.content {
    flex: 1;     
    padding: 20px;
    padding-top: 5px;
}

.projectManagement {
    position: relative;
    z-index: 1000;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 4.2rem;
    margin-top: 10px;
    margin-bottom: 10px;
}