.overall {
    height: 100vh;  /* 视窗的100%高度 */
    overflow-y: auto;  /* 内容超出时显示滚动条 */
    overflow-x: none;
}

.container {
    display: flex;  
    height: 100vh;
}

.content {
    flex: 1;     
    padding: 20px;
    padding-top: 5px;
}

.projectManagement {
    position: relative;
    z-index: 1000;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 4.2rem;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 32px;
    z-index: -1;
}

.projectListHeader {
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
  }

  .projectListTitle {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin: 0;
    white-space: nowrap; /* 防止换行 */
  }
  
  .viewAllLink {
    font-size: 14px;
    font-weight: 500;
    color: #344054;
    margin: 0.5rem 0 0 0;
    white-space: nowrap; /* 防止换行 */
  }