.projectManagementCard{
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap:16px;
    flex: 1 0 0;
    border-radius: 12px;
    border: 1px solid var(--Gray-200, #E4E7EC);
    background: var(--Base-White, #FFF);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.iconContainer{
    display: flex;
    width: 24px;
    height: 24px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid var(--Gray-200, #E4E7EC);
    background: var(--Base-White, #FFF);
    box-shadow: 0px 0px 0px 1px rgba(16, 24, 40, 0.18), 0px -2px 0px 0px rgba(16, 24, 40, 0.05), 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.headerandnumber{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}
.title{
    align-self: stretch;
    color: var(--Gray-600, #475467);
    /* Text sm/Medium */
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
}

.numberContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    row-gap: 12px;
    align-self: stretch;
    flex-wrap: wrap;
}
.number{
    color: var(--Gray-900, #101828);
    /* Display md/Semibold */
    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px; /* 122.222% */
    letter-spacing: -0.72px;
}