.buttonContainer {
    display: flex;
    justify-content: center;
    padding-top: 40px; /* 顶部间距 */
}

.submitButton {
    width: 120px; /* 按钮宽度，根据需要调整 */
    height: 40px;
    margin: 0 auto; /* 水平居中 */
    background-color: #4A90E2; /* 蓝色背景 */
    color: white; /* 白色文字 */
    font-family: Arial, sans-serif; /* Arial字体，没有则使用无衬线字体 */
    font-size: 16px; /* 字体大小 */
    font-weight: bold; /* 粗体 */
    padding: 12px 24px; /* 内边距，根据按钮大小调整 */
    border: none; /* 无边框 */
    border-radius: 20px; /* 圆角边框 */
    cursor: pointer; /* 鼠标悬浮时显示指针 */
    outline: none; /* 点击时不显示轮廓 */
    transition: background-color 0.3s; /* 背景颜色过渡效果 */
}

.submitButton:hover {
    background-color: #357ABD; /* 悬浮时的背景色 */
}