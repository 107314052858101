.projectManagement {
    position: relative;
    top: 0;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 4.2rem;
    padding: 1rem 2rem;
}

.textContent {
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden; /* 防止文本溢出 */
}

.title {
    font-size: 30px;
    font-weight: bold;
    color: #333;
    margin: 0;
    white-space: nowrap; /* 防止换行 */
}

.subtitle {
    font-size: 16px;
    color: #475467;
    margin: 0.5rem 0 0 0;
    white-space: nowrap; /* 防止换行 */
}

.createButton {
    width: 177px; /* 设置固定宽度 */
    height: 44px; 
    background-color: #F5853F;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    flex-shrink: 0;
    margin-right: 3.2rem;
    margin-top: -16px;
}

.rightContent {
    display: flex;
    align-items: center;
    margin-left: auto;
}

.rowContainer {
    display: flex;
    align-items: flex-end;
}

.editStatus {
    margin-left: 20px;
    white-space: nowrap;
}
