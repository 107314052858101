.overall {
    height: 100vh;  /* 视窗的100%高度 */
    overflow-y: auto;  /* 内容超出时显示滚动条 */
    overflow-x: auto;
}
.container {
    display: flex;  
    height: 100vh;
}

.content {
    flex: 1;     
    padding: 20px;
    padding-top: 5px;
}

.Infocontainer {
    font-family: Arial, sans-serif;
    padding: 20px;
    max-width: 600px;
    margin: auto;
    background-color: #fff;
    text-align: center;
}

.title {
    color: var(--Gray-900, #101828);
    text-align: center;
    /* Text lg/Semibold */
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 155.556% */
    }

.amount {
    color: var(--Gray-900, #101828);
    text-align: center;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 116.667% */

}

.message {
    color: var(--Gray-600, #475467);
    text-align: center;
    /* Text sm/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}

.detailsBox {
    background-color:  #FAFAFA;
    padding: 20px;
    border-radius: 8px;
    text-align: left;
    margin-top: 20px;
    width: 400px;
}

.detailItem {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between; /* Makes the elements spread out */
    align-items: center; /* Vertically center the elements */
}

.detailItem strong {
    overflow: hidden;
    color: #A4A4A4;

    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
}

.detailItem p {
    margin: 0;
}

.backButton {
    padding: 10px 20px;
    margin-top: 20px;
    font-size: 16px;
    color: #fff;
    background-color: #283362;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}