.projectManagement {
    position: relative;
    z-index: 1000;
    display: flex;
    justify-content: flex-start; /* 从左到右排列 */
    align-items: center;
    width: 100%;
    height: 4.2rem;
    padding: 0 1.5rem;
    padding-bottom: 1.5rem;
}

.textContent {
    flex-grow: 0; /* 不让它占用额外空间 */
    white-space: nowrap;
    overflow: hidden;
    margin-right: 0; /* 确保没有右边距 */
}

.createButton {
    width: 80px;
    height: 40px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    flex-shrink: 0; /* 不允许收缩 */
    margin-left: 625px; /* 确保没有左边距 */
}

.editStatus {
    margin-left: 16px; /* 标题和编辑链接之间的间隔 */
    white-space: nowrap; /* 防止编辑状态文字折行 */
}

