.badgeActive {
    display: flex;
    width: 50%;
    padding: 2px 8px;
    align-items: center; /* Centers content vertically */
    justify-content: center; /* Centers content horizontally */
    border-radius: 16px;
    border: 1px solid var(--Success-200, #ABEFC6);
    background: var(--Success-50, #ECFDF3);
}

.badgeActiveTxt {
    color: var(--Success-700, #067647);
    text-align: center;
    /* Text xs/Medium */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
    text-decoration-line: underline;
}


.badgePaused {
    display: flex;
    width: 50%;
    padding: 2px 8px;
    align-items: center; /* Centers content vertically */
    justify-content: center; /* Centers content horizontally */
    border-radius: 16px;
    border: 1px solid #FECDCA;
    background: #FEF3F2;
}

.badgePausedTxt {
    color: #B42318;
    text-align: center;

    /* Text xs/Medium */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
    text-decoration-line: underline;
}

.badgeCompleted {
    display: flex;
    width: 50%;
    padding: 2px 8px;
    align-items: center; /* Centers content vertically */
    justify-content: center; /* Centers content horizontally */
    border-radius: 16px;
    border: 1px #E4E7EC;
    background: #F9FAFB;
}

.badgeCompletedTxt {
    color: #344054;

    text-align: center;
    /* Text xs/Medium */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
}