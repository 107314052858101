.overall {
    height: 100vh;  /* 视窗的100%高度 */
    overflow-y: auto;  /* 内容超出时显示滚动条 */
    overflow-x: auto;
}
.container {
    display: flex;  
    height: 100vh;
}

.content {
    flex: 1;     
    padding: 20px;
    padding-top: 5px;
}

.cardContainer {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 24px;
    align-self: stretch;
    flex-wrap: wrap;
    margin-left: 30px;
    margin-right: 10px;
}