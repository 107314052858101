.header {
    position: relative;
    top: 0;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 4.2rem;
    background: var(--colors-primary, #ffffff);
    padding-left: 2.0rem;
    border-bottom: 1px solid #d3d3d3;
  }
  
  .logoBox {
    padding-left: 0.5rem;
  }
  
  .headerTitle {
    color: var(--Headerwhite, white);
    font-size: 1rem;
  }
  
  .centeredImg {
    max-width: 100%;
    max-height: 100%;
    width: 170px;
    height: auto;
    margin-bottom: 0px;
  }
  
  .rightContent {
    display: flex;
    align-items: center;
    padding-right: 4.5rem;
  }

.titleContainer {
    display: flex;
    flex-direction: column;
    font-family: Arial, sans-serif;
    align-items: flex-start;
    padding-right: 10px;
    width: 70px;
}

.mainTitle {
    font-size: 15px;
    font-weight: bold;
    color: #000000;
}

.subTitle {
  font-size: 10px; /* 副标题的字体大小 */
  color: #A3A3A3; /* 灰色字体 */
  margin-top: -5%;
  width: 60px; /* 设置容器的宽度 */
  overflow: hidden; /* 隐藏超出内容 */
  white-space: nowrap; /* 不换行 */
  text-overflow: ellipsis; /* 添加省略号 */
}